import Chips from '@/components/chips';
import TableWrapper from '@/components/tableWrapper';
import { Paper, TableCell, Typography } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import { isEmpty } from 'lodash-es';

export default function LastSentSection( { data, timezone } ) {
	if ( isEmpty( data.metadata?.sentDates ) ) return null;
	
	return (
		<TableWrapper>
			<TableCell align='left' width={100}/>
			<TableCell align='left' width='70%'>
				<Paper sx={{ p: 2, mt: 2, bgcolor: 'background.default' }}>
					<TableWrapper>
						<TableCell>
							<Typography color='text.secondary'>Visible to you only</Typography>
						</TableCell>
					</TableWrapper>
					<TableWrapper>
						<TableCell width='20%'>
							<Typography>
								Last sent:
							</Typography>
						</TableCell>
						<TableCell>
							<Chips chipProps={{ variant: 'alpha', color: 'warning' }}>
								{data.metadata.sentDates.map( ( date ) => +new Date( date )
									? formatInTimeZone( date, timezone || undefined, 'PPp' )
									: '-',
								)}
							</Chips>
						</TableCell>
					</TableWrapper>
				</Paper>
			</TableCell>
		</TableWrapper>
	
	);
}

