import EmailField from '@/components/form/fields/email';
import FormTextField from '@/components/form/fields/textField';
import PageLinkComponent from '@/components/page/linkComponent';
import TableWrapper from '@/components/tableWrapper';
import usePermissions from '@/providers/auth/usePermissions';
import { EmailType } from '@/types/schema';
import { Edit as EditIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, TableCell, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import SendViaField from '.././email/sendViaField';

export function EmailComponent( {
	emailView,
	sendViaType,
	setSendViaType,
}: {
	emailView: ReactNode,
	sendViaType?: EmailType,
	setSendViaType?: Dispatch<SetStateAction<EmailType>>
} ) {
	const { t } = useTranslation();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isOwner = usePermissions( [ 'OWNER' ] );
	
	if ( isMobile ) {
		return (
			<Stack spacing={1}>
				<TableWrapper>
					<TableCell align='left' width='100%'>
						<Typography>From</Typography>
						<SendViaField sendViaType={sendViaType} setSendViaType={setSendViaType}/>
					</TableCell>
				</TableWrapper>
				<EmailField
					name='to'
					textFieldProps={{ label: 'To' }}
				/>
				<EmailField
					name='cc'
					textFieldProps={{ label: 'cc' }}
				/>
				<FormTextField
					fullWidth
					name='subject'
					label={t( 'common:subject' )}
				/>
				<FormTextField
					fullWidth
					multiline
					name='note'
					label={t( 'common:note' )}
					minRows={2}
					maxRows={10}
				/>
				{emailView}
			</Stack>
		);
	}
	
	return (
		<Box
			sx={{
				'px'                  : 1,
				'.emailFirstTableCell': {
					color         : 'text.secondary',
					display       : 'flex',
					alignItems    : 'center',
					justifyContent: 'start',
					width         : 100,
					pt            : 1,
				},
			}}>
			<TableWrapper>
				<TableCell align='left' className='emailFirstTableCell'>
					Send Via:
				</TableCell>
				<TableCell align='left' width='70%'>
					<SendViaField sendViaType={sendViaType} setSendViaType={setSendViaType}/>
				</TableCell>
			</TableWrapper>
			<TableWrapper>
				<TableCell align='left' className='emailFirstTableCell'>
					{t( 'common:to' )}:
				</TableCell>
				<TableCell align='left' width='70%'>
					<EmailField name='to'/>
				</TableCell>
			</TableWrapper>
			<TableWrapper>
				<TableCell align='left' className='emailFirstTableCell'>
					{t( 'common:copy' )}:
				</TableCell>
				<TableCell align='left' width='70%'>
					<EmailField name='cc'/>
				</TableCell>
			</TableWrapper>
			<TableWrapper>
				<TableCell align='left' className='emailFirstTableCell'>
					{t( 'common:subject' )}:
				</TableCell>
				<TableCell align='left' width='70%'>
					<Box>
						<FormTextField
							fullWidth
							name='subject'
						/>
					</Box>
				</TableCell>
			</TableWrapper>
			<TableWrapper>
				<TableCell align='left' className='emailFirstTableCell'>
					{t( 'common:body-note' )}:
				</TableCell>
				<TableCell align='left' width='70%'>
					<FormTextField
						fullWidth
						multiline
						name='note'
						label={isMobile ? t( 'common:note' ) : undefined}
						minRows={2}
						maxRows={10}
						InputProps={{
							endAdornment: isOwner && (
								<Tooltip title='You can edit the default email body in the settings page'>
									<IconButton
										sx={{ alignSelf: 'flex-start', mt: 1 }}
										component={PageLinkComponent}
										href={`${process.env.NEXT_PUBLIC_SITE_URL}/dashboard/settings/doc?tab=preferences`}
										target='_blank'>
										<EditIcon fontSize='small'/>
									</IconButton>
								</Tooltip>
							),
						}}
					/>
				</TableCell>
			</TableWrapper>
			<TableWrapper>
				<TableCell align='left' className='emailFirstTableCell'/>
				<TableCell align='left' width='70%'>
					<Box>
						{emailView}
					</Box>
				</TableCell>
			</TableWrapper>
		</Box>
	);
}
