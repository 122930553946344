export const openIntegrationPopup = async (
	url: string,
	refetch?: () => void,
	target: string = 'Integration',
	expectedMessage?: string,
) => new Promise<void>( ( resolve,
	reject ) => {
	const width = 500;
	const height = 600;
	const left = window.screen.width / 2 - width / 2;
	const top = window.screen.height / 2 - height / 2;
	
	const integrationWindow = window.open(
		url,
		target,
		`width=${width},height=${height},top=${top},left=${left}`,
	);
	
	if ( !integrationWindow ) {
		reject( new Error( 'Failed to open integration window' ) );
		return;
	}
	
	const handleMessage = ( event: MessageEvent ) => {
		if ( event.data.type === 'auth_error' ) {
			integrationWindow.close();
			window.removeEventListener( 'message', handleMessage );
			reject( new Error( event.data.error ) );
		} else if ( event.origin === process.env.NEXT_PUBLIC_SERVER_URL && event.data === expectedMessage ) {
			integrationWindow.close();
			refetch?.();
			window.removeEventListener( 'message', handleMessage );
			resolve();
		}
	};
	
	const handleWindowClose = () => {
		window.removeEventListener( 'message', handleMessage );
		if ( !integrationWindow.closed ) {
			integrationWindow.close();
		}
		resolve();
	};
	
	window.addEventListener( 'message', handleMessage );
	
	const interval = setInterval( () => {
		if ( integrationWindow.closed ) {
			clearInterval( interval );
			handleWindowClose();
		}
	}, 1000 );
} );
