import { CancelRounded as CancelRoundedIcon } from '@mui/icons-material';
import type { TextFieldProps } from '@mui/material';
import { Autocomplete, Chip, ClickAwayListener } from '@mui/material';
import { useField } from 'formik';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import FormattedTextField from '../../formattedTextField';

const isEmailValid = ( email: string ) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test( email );

export default function EmailField( { name, textFieldProps }: { name: string, textFieldProps?: TextFieldProps } ) {
	const [ , { value = null, touched, error }, { setValue, setTouched } ] = useField( name );
	const [ , {}, { setValue: setValidateToEmails } ] = useField( 'validateToEmails' );
	const [ , {}, { setValue: setValidateCCEmails } ] = useField( 'validateCCEmails' );
	const [ , {}, { setValue: setValidateBccEmails } ] = useField( 'validateBccEmails' );
	const [ inputValue, setInputValue ] = useState( '' );
	const handleChange = ( event: SyntheticEvent, emails: string[] ) => {
		setTouched( true );
		setValue( emails );
		if ( name === 'to' ) setValidateToEmails?.( emails );
		if ( name === 'bcc' ) setValidateBccEmails?.( emails );
		if ( name === 'cc' ) setValidateCCEmails?.( emails );
		event.persist();
	};
	
	const handleClickAway = () => {
		if ( inputValue.length > 0 ) {
			if ( name === 'to' ) setValidateToEmails?.( [ ...value, inputValue ] );
			if ( name === 'bcc' ) setValidateBccEmails?.( [ ...value, inputValue ] );
			if ( name === 'cc' ) setValidateCCEmails?.( [ ...value, inputValue ] );
			setValue( [ ...value, inputValue ] );
			setInputValue( '' );
		}
	};
	
	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Autocomplete
				multiple
				freeSolo
				disableClearable
				fullWidth
				options={[] as string[]}
				renderTags={( emails, getTagProps ) => emails.map( ( email, index ) => (
					<Chip
						key={index}
						deleteIcon={<CancelRoundedIcon/>}
						label={email}
						sx={{
							'& .MuiChip-label'   : {
								textTransform: 'none',
							},
							'.MuiChip-deleteIcon': {
								color   : 'primary.main',
								fontSize: 19,
								ml      : .5,
							},
						}}
						variant='alpha'
						color={isEmailValid( email ) ? 'primary' : 'error'}
						{...getTagProps( { index } )}
					/>
				) )}
				value={value}
				inputValue={inputValue?.toLowerCase()}
				renderInput={( params ) => (
					<FormattedTextField
						size='medium'
						{...params}
						inputProps={{
							...params.inputProps,
							onKeyDown: ( event ) => {
								switch ( event.code ) {
									case 'Tab':
									case 'Comma':
									case 'Space': {
										if ( inputValue.length > 0 ) {
											handleChange( event, value.concat( [ inputValue ] ) );
											setInputValue( '' );
											event.preventDefault();
										}
										break;
									}
								}
							},
						}}
						name={name}
						error={touched[ name ] && Boolean( error )}
						helperText={touched[ name ] && error}
						variant='outlined'
						{...textFieldProps}
					/>
				)}
				onChange={handleChange}
				onInputChange={( event, newInputValue ) => {
					const options = newInputValue.split( /[ ,]+/ );
					const fieldValue = value
						.concat( options )
						.map( ( x ) => x.trim() )
						.filter( ( x ) => x );
					if ( name === 'to' ) setValidateToEmails?.( fieldValue );
					if ( name === 'bcc' ) setValidateBccEmails?.( fieldValue );
					if ( name === 'cc' ) setValidateCCEmails?.( fieldValue );
					
					if ( options.length > 1 ) {
						
						handleChange( event, fieldValue );
					} else {
						setInputValue( newInputValue );
					}
				}}
			/>
		</ClickAwayListener>
	);
}

