import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import StyledImage from '@/components/styledImage';
import useUserInfo from '@/providers/auth/useUserInfo';
import { EmailType } from '@/types/schema';
import { isProduction } from '@/utils/config';
import { openIntegrationPopup } from '@/utils/integrationPopup';
import { Checkbox, Chip, ListItemIcon, ListItemText, MenuItem, Select, Stack, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useRef } from 'react';

export default function SendViaField( {
	sendViaType,
	setSendViaType,
}: {
	sendViaType?: EmailType,
	setSendViaType?: Dispatch<SetStateAction<EmailType>>
} ) {
	const theme = useTheme();
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const selectRef = useRef<HTMLInputElement>();
	const queryClient = useQueryClient();
	
	const gmailData = staff?.gmailData;
	const outlookData = staff?.outlookData;
	const yahooData = staff?.yahooData;
	
	return (
		<Select
			ref={selectRef}
			fullWidth
			value={sendViaType}
			MenuProps={{
				sx: {
					'.MuiMenu-paper'     : {
						width: selectRef?.current?.offsetWidth,
					},
					'.MuiButtonBase-root': {
						'&.Mui-selected': {
							'p, span': { color: 'text.primary' },
							'bgcolor': 'alpha.primary',
							'&:hover': {
								bgcolor: 'alpha.primary',
							},
						},
					},
				},
			}}
			renderValue={() => {
				switch ( sendViaType ) {
					case 'GMAIL':
						return (
							<Stack direction='row' alignItems='center' spacing={1}>
								<StyledImage src='/images/gatewayConnections/gmail.png' sx={{ width: 20 }}/>
								<Chip
									sx={{ textTransform: 'lowercase' }}
									label={gmailData?.email}
									variant='alpha'
									color='primary'
								/>
							</Stack>
						);
					case 'INVOISS':
						return (
							<Stack direction='row' alignItems='center' spacing={1}>
								<StyledImage
									src={`/images/invoiss-logo-${theme.palette.mode === 'dark' ? 'light' : 'dark'}.png`}
									sx={{ width: 20 }}
								/>
								<Chip
									sx={{ textTransform: 'lowercase' }}
									label='Invoiss'
									variant='alpha'
									color='primary'
								/>
							</Stack>
						);
					case 'OUTLOOK':
						return (
							<Stack direction='row' alignItems='center' spacing={1}>
								<StyledImage src='/images/outlook-icon.png' sx={{ width: 20 }}/>
								<Chip
									sx={{ textTransform: 'lowercase' }}
									label={outlookData?.email}
									variant='alpha'
									color='primary'
								/>
							</Stack>
						);
					case 'YAHOO':
						return (
							<Stack direction='row' alignItems='center' spacing={1}>
								<StyledImage src='/images/yahoo-icon.png' sx={{ width: 30 }}/>
								<Chip
									sx={{ textTransform: 'lowercase' }}
									label={yahooData?.email}
									variant='alpha'
									color='primary'
								/>
							</Stack>
						);
					default:
						return 'Via Invoiss';
				}
			}}>
			<MenuItem
				value='GMAIL'
				onClick={async () => {
					if ( !gmailData?.accessToken ) {
						enqueueSnackbar( 'Please connect your Gmail account first' );
						return;
					}
					setSendViaType?.( 'GMAIL' );
					await queryClient.invalidateQueries( [ 'user' ] );
				}}>
				<Stack direction='row' alignItems='center' spacing={1}>
					<Checkbox checked={sendViaType === 'GMAIL'}/>
					<ListItemIcon>
						<StyledImage src='/images/gatewayConnections/gmail.png' sx={{ width: 20 }}/>
					</ListItemIcon>
					<ListItemText
						primary={`Via my Gmail ${gmailData?.email ? `(${gmailData?.email})` : ''}`}
						primaryTypographyProps={{ color: 'text.primary' }}
						secondary={!gmailData?.email && 'By connecting your Gmail account, you can send emails directly from your Gmail address.'}
					/>
					{!gmailData?.accessToken && (
						<AsyncLoadingButton
							color='success'
							variant='contained'
							onClick={async ( e ) => {
								e.stopPropagation();
								await openIntegrationPopup(
									`${process.env.NEXT_PUBLIC_SERVER_URL}/api/gmail/auth?staffId=${staff?.id}`,
									() => queryClient.invalidateQueries( [ 'user' ] ),
									'GoogleAuth',
									'auth_complete',
								);
							}}>
							Connect
						</AsyncLoadingButton>
					)}
				</Stack>
			</MenuItem>
			<MenuItem
				value='OUTLOOK'
				onClick={async () => {
					if ( !outlookData?.accessToken ) {
						enqueueSnackbar( 'Please connect your Outlook account first' );
						return;
					}
					setSendViaType?.( 'OUTLOOK' );
					await queryClient.invalidateQueries( [ 'user' ] );
				}}>
				<Stack direction='row' alignItems='center' spacing={1}>
					<Checkbox checked={sendViaType === 'OUTLOOK'}/>
					<ListItemIcon>
						<StyledImage src='/images/outlook-icon.png' sx={{ width: 20 }}/>
					</ListItemIcon>
					<ListItemText
						primary={`Via my Outlook email ${outlookData?.email ? `(${outlookData?.email})` : ''}`}
						primaryTypographyProps={{ color: 'text.primary' }}
						secondary={!outlookData?.email && 'By connecting your Outlook account, you can send emails directly from your Outlook address.'}
					/>
					{!outlookData?.accessToken && (
						<AsyncLoadingButton
							color='success'
							variant='contained'
							onClick={async ( e ) => {
								e.stopPropagation();
								await openIntegrationPopup(
									`${process.env.NEXT_PUBLIC_SERVER_URL}/api/emailConnections/outlook/auth?staffId=${staff?.id}`,
									() => queryClient.invalidateQueries( [ 'user' ] ),
									'OutlookAuth',
									'auth_complete',
								);
							}}>
							Connect
						</AsyncLoadingButton>
					)}
				</Stack>
			</MenuItem>
			{!isProduction && (
				<MenuItem
					value='YAHOO'
					onClick={async () => {
						if ( !yahooData?.accessToken ) {
							enqueueSnackbar( 'Please connect your Yahoo account first' );
							return;
						}
						setSendViaType?.( 'YAHOO' );
						await queryClient.invalidateQueries( [ 'user' ] );
					}}>
					<Stack direction='row' alignItems='center' spacing={1}>
						<Checkbox checked={sendViaType === 'YAHOO'}/>
						<ListItemIcon>
							<StyledImage src='/images/yahoo-icon.png' sx={{ width: 30 }}/>
						</ListItemIcon>
						<ListItemText
							primary={`Via my Yahoo email ${yahooData?.email ? `(${yahooData?.email})` : ''}`}
							primaryTypographyProps={{ color: 'text.primary' }}
							secondary={!yahooData?.email && 'By connecting your Yahoo account, you can send emails directly from your Outlook address.'}
						/>
						{!yahooData?.accessToken && (
							<AsyncLoadingButton
								color='success'
								variant='contained'
								onClick={async ( e ) => {
									e.stopPropagation();
									await openIntegrationPopup(
										`/api/emailConnections/yahoo/auth?staffId=${staff?.id}`,
										() => queryClient.invalidateQueries( [ 'user' ] ),
										'YahooAuth',
										'auth_complete',
									);
								}}>
								Connect
							</AsyncLoadingButton>
						)}
					</Stack>
				</MenuItem>
			)}
			<MenuItem
				value='INVOISS'
				onClick={async () => {
					setSendViaType?.( 'INVOISS' );
					await queryClient.invalidateQueries( [ 'user' ] );
				}}>
				<Stack direction='row' alignItems='center' spacing={1}>
					<Checkbox checked={sendViaType === 'INVOISS'}/>
					<ListItemIcon>
						<StyledImage
							src={`/images/invoiss-logo-${theme.palette.mode === 'dark' ? 'light' : 'dark'}.png`}
							sx={{ width: 20 }}
						/>
					</ListItemIcon>
					<ListItemText
						primary='Via Invoiss (Replies will be sent to your company email.)'
					/>
				</Stack>
			</MenuItem>
		</Select>
	);
}

