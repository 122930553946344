import { Box, Typography } from '@mui/material';
import StyledImage from './styledImage';

export default function QRScanBox( { data } ) {
	return (
		<Box
			sx={{
				border      : 1,
				borderRadius: 2,
				borderColor : 'divider',
				p           : 1,
				display     : 'inline-block',
				img         : { width: { xs: 65, sm: 90 }, height: { xs: 65, sm: 90 } },
			}}>
			<StyledImage
				alt='qr-code'
				style={{ borderRadius: 2 }}
				src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${data}`}
			/>
			<Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
				Scan
			</Typography>
		</Box>
	);
}
