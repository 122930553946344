import { CheckRounded as CheckRoundedIcon, CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import type { BoxProps, ChipProps } from '@mui/material';
import { Box, Chip } from '@mui/material';
import type { ReactNode } from 'react';

export default function Chips( {
	children,
	chipProps,
	...boxProps
}: {
	children: ReactNode[],
	chipProps?: ChipProps
} & BoxProps ) {
	return (
		<Box sx={{ '.MuiChip-root': { mr: 1 } }} {...boxProps}>
			{children.map( ( item, index ) => (
				<Chip
					key={index}
					variant='alpha'
					label={item}
					{...chipProps}
				/>
			) )}
		</Box>
	);
}

export function BoolChip( { value }: { value: boolean } ) {
	return value
		? <CheckRoundedIcon sx={{ fontSize: 20, color: 'success.main' }}/>
		: <CloseRoundedIcon sx={{ fontSize: 20, color: 'error.main' }}/>;
}
