import Loading from '@/components/loading';
import { axiosClient, TEMP_HARDCODED_ERROR } from '@/data';
import SyncToClover from '@/helpers/syncToClover';
import { swipeCardAndMeteredBilling, updateStock } from '@/pages/dashboard/commerce/orders/utils';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Order } from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import { isString, round, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type CloverDeviceActionType = 'Edit On Clover' | 'Swipe Card';

async function importOrder( invoice: Order, staffId: string ) {
	try {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
			id         : invoice.id,
			reverseSync: true,
			staffId,
		} );
		
	} catch ( e ) {
		console.error( e );
		throw new Error( TEMP_HARDCODED_ERROR );
	}
}

export default function DeviceLoadingModal( {
	invoice,
	title,
	bodyTitle,
	buttonText,
	secondaryTitle,
}: {
	invoice: Order,
	title: CloverDeviceActionType,
	bodyTitle: string,
	buttonText: string,
	secondaryTitle?: string
} ) {
	
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const { closeModal } = useModalControls();
	const [ cloverDeviceOpened, setShowCloverDeviceOpened ] = useState( false );
	
	const remaining = invoice.grandTotal - ( invoice.paidTotal || 0 );
	const subTotal = invoice.subTotal;
	const taxTotal = invoice.taxTotal;
	const companyMetadata = staff?.company?.metadata || invoice.company?.metadata;
	const cardFee = round( companyMetadata?.cardFee || 0, 2 ) / 100;
	const payingBeforeTax = remaining - taxTotal * remaining / ( subTotal + taxTotal );
	const cardFeeAmount = payingBeforeTax * cardFee;
	const hasCardFee = invoice.metadata?.cardFee;
	
	const requireCardFee = title === 'Swipe Card' && !hasCardFee && !invoice.metadata?.enableCardFee && invoice.company.metadata.cardFee;
	
	async function editOnClover( invoice: Order ) {
		try {
			if ( window?.Android ) {
				if ( typeof invoice?.externalId === 'string' ) {
					window.Android.onOpenOrder( invoice?.externalId );
				}
			}
			await postCloverMeteredBilling( {
				orderId  : invoice.id,
				gatewayId: invoice?.gateway?.id,
				eventType: 'Edit on Clover',
				key      : 'editOnClover',
				staffId  : staff?.id,
				clientId : invoice.client?.id,
			} )
				.catch( () => [] );
			setShowCloverDeviceOpened( true );
		} catch {
			enqueueSnackbar( 'Failed to open the edit page on the device', { variant: 'error' } );
		}
	}
	
	const swipeCard = async ( invoice: Order, requireCardFee?: boolean ) => {
		
		if ( requireCardFee ) {
			try {
				await axiosClient.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/tempCardFee`, {
					id     : invoice.id,
					cardFee: cardFeeAmount,
				} );
				enqueueSnackbar( t( 'commerce:card-fee-has-been-added' ), { variant: 'info' } );
			} catch {
				throw 'Something went wrong when applying the card fee.';
			}
		}
		
		if ( invoice.externalId ) {
			await swipeCardAndMeteredBilling( invoice, staff );
			setShowCloverDeviceOpened( true );
		} else {
			try {
				const cloverInvoice = await SyncToClover( invoice );
				await updateStock( invoice, staff?.company, 'manualSyncStock' );
				await swipeCardAndMeteredBilling( cloverInvoice?.commerce, staff );
				setShowCloverDeviceOpened( true );
			} catch ( e ) {
				const cloverErrors = e?.response.data?.cloverErrors;
				if ( cloverErrors ) {
					throw isString( cloverErrors )
						? cloverErrors
						: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred - Clover.com';
				} else {
					enqueueSnackbar( t( 'commerce:sync-fail' ), { variant: 'default' } );
				}
				console.error( e );
				throw e;
			}
		}
	};
	
	return (
		<ResponsiveModalContainer
			hideClose={cloverDeviceOpened}
			saveButtonText={cloverDeviceOpened ? 'Done' : buttonText}
			closeOnSave={false}
			title={title}
			secondaryTitle={secondaryTitle || ''}
			actionItems={[
				requireCardFee && !cloverDeviceOpened && {
					name   : 'Pay without card fee',
					onClick: async () => {
						await swipeCard( invoice );
					},
				},
			].filter( Boolean )}
			onSave={async () => {
				if ( cloverDeviceOpened ) {
					// will run when Done is clicked
					await importOrder( invoice, staff?.id );
					closeModal();
				} else {
					// Will run the function above
					switch ( title ) {
						case 'Edit On Clover':
							await editOnClover( invoice );
							break;
						case 'Swipe Card':
							await swipeCard( invoice, requireCardFee );
							break;
					}
				}
			}}>
			{cloverDeviceOpened ? (
				<Stack>
					<Typography className='center' variant='h4'>
						{bodyTitle}
					</Typography>
					<Loading/>
					<Typography className='center' color='warning.main'>
						Press done when complete
					</Typography>
				</Stack>
			) : (
				<Stack>
					<Typography>
						{title === 'Swipe Card'
							? `Pay this ${toLower( invoice.type )} on Clover.`
							: `Open the edit page on Clover to edit this ${toLower( invoice.type )}.`}
					</Typography>
				</Stack>
			)}
		</ResponsiveModalContainer>
	);
	
}
