import { queryGraphQL } from '@/data/apollo';
import { ClientsRead } from '@/data/management/client.graphql';
import { Client, Order, QueryClientsReadArgs, Staff } from '@/types/schema';
import axios from 'axios';
import { isNil, omitBy } from 'lodash';
import { toLower } from 'lodash-es';

export const clientHasDifferentMerchant = ( order: Order, client: Client ) => client.gateway?.externalId
	&& order.companyLocation?.gateway?.externalId && order.companyLocation.gateway.externalId !== client.gateway.externalId;

export const fetchClientForMerchant = async ( order: Order, client: Client ) => {
	if ( client.email || client?.name || client?.contact ) {
		const clientFilter = {
			email  : client.email || null,
			name   : client?.name || null,
			contact: client?.contact || null,
		};
		const { clientsRead } = await queryGraphQL<QueryClientsReadArgs>( {
			query    : ClientsRead,
			variables: {
				options: {
					limit : 1,
					filter: {
						...omitBy( clientFilter, isNil ),
						gateway: order.companyLocation?.gateway?.id,
					},
				},
			},
		} );
		return clientsRead.items?.[ 0 ];
	}
};

export const createNewClientForMerchant = async ( order: Order, client: Client, staff: Staff ) => {
	const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/management/createClient`, {
		email         : client.email ? toLower( client.email ) : null,
		name          : client.name,
		contact       : client.contact,
		cell          : client?.cell,
		staff         : staff?.id || client?.staff?.id || order?.staff?.id || null,
		phone         : client?.phone,
		company       : order.company?.id || client.company?.id,
		noClientFilter: true,
		image         : client.logo || null,
		addresses     : client?.addresses,
		statement     : client.statement,
		gateway       : order.companyLocation?.gateway?.id,
		clientMetadata: {
			companyNumber: client?.metadata?.companyNumber,
			exemptFromTax: client?.metadata?.exemptFromTax,
		},
	} );
	
	return data?.clientWrite;
};

export async function getClientForMerchant( order, client, staff ): Promise<Client | null> {
	if ( !client || !order ) return null;
	if ( clientHasDifferentMerchant( order, client ) ) {
		// fetch client for merchant
		const merchantClient: Client = await fetchClientForMerchant( order, client );
		if ( merchantClient ) {
			return merchantClient;
		} else {
			//create new client
			const newClient: Client = await createNewClientForMerchant( order, client, staff );
			if ( newClient ) {
				return newClient;
			}
		}
		
	} else {
		return client as Client;
	}
}
